.containerok {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .location-select-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .location-select {
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    max-width: 300px;
  }
  
  .inventory-table-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .inventory-table {
    width: 100%;
    max-width: 1000px; /* Fix szélesség, hogy minden elférjen */
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .inventory-table th, .inventory-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    font-size: 1rem;
  }
  
  .inventory-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .input-field {
    padding: 5px;
    font-size: 1rem;
  }
  
  .submit-button {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 20px auto;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  /* Mobil stílusok */
  @media (max-width: 768px) {
    .input-field {
        font-size: 0.9rem;
        width: 35px;
    }
    h1 {
      font-size: 1.5rem;
    }
  
    .inventory-table {
      font-size: 0.9rem;
      width: 100%; /* Mobilnézethez igazítás */
    }
  
    .inventory-table th, .inventory-table td {
      padding: 6px;
      font-size: 0.85rem;
    }
  
    .submit-button {
      font-size: 0.9rem;
    }
  }

  .qr-button {
    margin: 10px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .qr-button:hover {
    background-color: #45a049;
  }
  .qr-reader {
    max-width: 50%;
    margin: auto;
    margin-bottom: 30px;
}