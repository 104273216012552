.navbar {
    position: fixed!important;
    right: 15px!important;
    bottom: 15px!important;
    width: 60px!important;
    height: 60px!important;
    border-radius: 100%;
    border: none;
    -webkit-box-shadow: -11px -7px 29px -11px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: -11px -7px 29px -11px rgba(0, 0, 0, 0.75);
        box-shadow: -11px -7px 29px -11px rgba(0, 0, 0, 0.75);
        cursor: pointer;
    background-color: white;
    z-index:500;
}
a.mastersButton {
  border: 1px solid grey;
  padding: 5px;
  color: #5d5d5d;
  font-size: 15px;
  border-radius: 5px;
  transition: 0.3s;
}
.bonustile {
  font-size: 13px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid;
  padding: 5px;
}
[role=tooltip].popup-content {
  width: auto!important;
  height: auto!important;
  text-align: center!important;
}
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
    .navbar {
        display: none;
    }
}
a.mastersButton:hover {
  border: 1px solid grey;
  padding: 5px;
  color: white;
  background-color: #5d5d5d;
  font-size: 15px;
  border-radius: 5px;
}
.faicon{
    font-size: 20px;
    color: #282c34;
}
.itemName {
  border: 1px solid gray;
  font-size: 15px;
  margin: 5px;
  padding: 5px;
}
html {
  background-color: #ffffff!important;
}
.itemType {
  background: rgba(255,255,255,0.4);
  padding: 3px;
  margin: 2px;
  text-align: left;
  display: inline-flex;
  padding-left: 10px;
  width:30%;
  justify-content: space-between;
}
button.inputButtonType {
  margin: 10px;
}
@media screen and (max-width: 40em) {
.responsiveTable tbody tr {
  border: 1px solid grey!important;
  padding: .25em;
  background: rgba(0,0,0,0.4)!important;
  color: white!important;
  margin: 5px!important;
}
}
@media screen and (max-width: 800px) {
  .itemType {
    width:90%!important;
  }
  .buntetesdiv {
    width: 85%!important;
    background: rgba(255,255,255,0.5);
    padding: 20px;
    border-radius: 10px;
    margin: 10px!important;
}
  .numberinput {
    width: 70%;
}
  }
.typeButton {
  border: none;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  }
  button.save-button {
    margin: 5px;
    border: none;
    padding: 5px;
    border-radius: 3px;
}
.famenu{
    margin-right: 5px;
}
.navMenu {
    background: none !important;
    color: white !important;
    display: block !important;
    text-align: center !important;
    margin: auto !important;
}
.active {
    background: white;
    border-radius: 10px;
}
button.buntetesbutton {
  width: 100%;
  border: none;
  border-radius: 5px;
  height: 30px;
  background: gray;
  color: white;
  transition: 0.3s;
  cursor: pointer;
}
button.buntetesbutton:hover {
  background: black;
}
.buntetesdiv {
  width: 30%;
  background: rgba(255,255,255,0.5);
  padding: 20px;
  border-radius: 10px;
  margin: auto;
}
.menuItem {
    margin: 0px;
    font-size: 18px;
    border-radius: 15px;
    text-align: left;
}
.background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 5;
}
.menuicon{
  margin-right: 15px;
}
.menuItem:hover {
  background: rgba(255, 255, 255, 0.397);
}
hr {
  color: rgba(255, 255, 255, 0.534);
  border:1px solid;
}
.fullscreen{
position: fixed !important;
    min-height: 100vh !important;
    width: 80% !important;
    top: 0 !important;
    right: 0 !important;
    background-color: white;
    z-index: 3;
    overflow: auto;
    color: rgb(0 0 0 / 100%);
    
}
.calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .headerImage{
    height: 100px;
    width: 80%;
  }
  .calendar-day {
    align-items: center;
    font-size: 15px;
    border-radius: 10px;
    justify-content: center;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  #active:active {
    color: red;
  }
  .selected {
    background-color: #d63031;
    color: #fff;
  }
  .loginButton{
    position: fixed;
    bottom: 0px;
  }
  .work-day{
    background-color: #00b894;
  }
  
  .selected-day {
    margin-right: 5px;
    padding: 3px 6px;
    background-color: #d63031;
    color: #fff;
    font-size: 15px;
    border-radius: 5px;
}
button.confirmbutton {
  padding: 10px;
  margin-top: 15px;
  display: block;
  border: none;
  background: #00b894;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}
p.description {
  font-size: 12px;
}
button.confirmbutton:hover {
  background: #009073;
  border-radius: 8px;
}
  
  .calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .selected-days {
    margin-bottom: 10px;
  }
  p.day {
    padding: 10px;
}
  .confirmation-button {
    padding: 10px 20px;
    background-color: #3f51b5;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .confirmation-button:hover {
    background-color: #303f9f;
  }
  
  .select-days-text {
    font-weight: bold;
    color: #999;
    margin-bottom: 5px;
  }
  
  .empty {
    cursor: default;
    border: none;
  }
  .calendar-days-header {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    margin-bottom: 5px;
  }
  .calendar-day-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    font-size: 15px;
    background-color: rgba(255,255,255,0.4);
    border-radius: 10px;
    font-weight: bold;
  }
  .infobuttonholder {
    display: flex ;
    align-items: flex-end;
    width: 100%;
    justify-content:end;

   
  }
  button.infobutton {
    display: block;
    width: 100%;
    padding: 5px;
    height: 20%;
    border: none;
    border-radius: 8px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;

}

.morebutton{
  border-radius: 8px!important;
  margin-top: 8px;
}
button.nextbutton {
    background: none;
    color: white;
    margin-top: 15px;
    border: 1px solid white;
    border-radius: 5px;
}
.jelmagyarazat {
    display: flex;
    font-size: 15px;
    align-items: center;
    margin-bottom: 5px;
}
/* animation */

@keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }
  .popup-content {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  }
  .selectUser{
    cursor: pointer;
  }

.contestButton {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 100px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 60px;
}
.background.active, .fullscreen.active {
  opacity: 1; /* Megjelenés */
  transform: translateY(0%); /* Megjelenés */
}
@media (min-width: 768px) {
  .mobile-navbar {
    display: none;
  }
  .fullscreen{
        width: 30% !important;
    }
  .headerImage {
    width: 30% !important;
  }
}
/* Mobil nézet */
@media (max-width: 768px) {
  .mobile-navbar {
    position: fixed;
    bottom: 0;
    background-color: rgba(255,255,255, 0.8)!important;
    backdrop-filter: blur(2px);
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 20px;
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.1); /* Árnyék a navbar alatt */
    z-index: 999; /* Biztosítja, hogy a navbar előtérben legyen */
    height: 35px!important;
  }

  .mobile-navbar .navMenu1 {
    width: 100%;
  }
  .App {
    margin-bottom: 70px;
  }
  .menuItem1 {
    display: block!important;
    padding: 0px!important;
    margin: 8px;
  }
  .mobile-navbar .menuItem1 {
    /* Stílusok a mobil nézetben lévő menüpontokhoz */
    margin-bottom: 5px;
  }
  .menuItem1 {
    font-size: 12px;
    
  }
  .faiconmenu{
    font-size: 16px;
  }
  /* Rejtett a hamburger ikon a mobil navbar-ból */
  .navbar {
    display: none;
  }

  /* Megjelenítés a fullscreen menünek */
  .fullscreen {
    display: block;
  }
}
.contestButton:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.contestButton:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.contestButton:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(2px);
}
  .users {
    display: inline-flex;
    border: 1px solid gray;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 5px;
    margin-bottom: 5px;
    }