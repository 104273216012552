.mobileInput{
    width: 250px;
    height: 35px;
    border-radius: 20px;
    text-align: center;
    border: 1px solid #BDBDBD;
    margin: 5px;
    margin-bottom: 15px;
    -webkit-box-shadow: 0px 7px 20px -16px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 7px 20px -16px rgba(0,0,0,0.75);
    box-shadow: 0px 7px 20px -16px rgba(0,0,0,0.75);
}
.itemList{
    padding: 15px;
    margin: 10px;
    border-radius: 15px;
    background-color: rgba(255,255,255,0.2);
    border: 2px solid black;
width: 250px;
margin: auto;
}
.items {
    width: 100%;
    display: inline-flex;
    padding: 5px;
    flex-wrap: wrap;
    }
.item {
    font-size: 14px;
        display: inline-block;
        color: white;
        padding: 3px;
        border-radius: 15px;
        margin-top: 5px;
        margin-right: 5px;
}
.addItemDiv{
    margin-top: 20px;
}
.hrc {
    margin: 12px 0;
    border: 0;
    text-align: center;
    
    &:before {
      content: "\2022 \2022 \2022";
      font-size: 40px;
      color: black;
    }
  }
.title {
    /* background-color: #2d3436; */
    /* display: inline-flex; */
    font-size: 16px;
    padding: 5px;
    border-radius: 5px;
    text-align: left;
    /* margin-left: 30px; */
    /* margin-right: 30px; */
    width: 90%;
    margin: auto;
    }
.submitbutton{
    margin-bottom: 5px;

    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    color: white;
    transition: 0.3s linear;
    cursor: pointer;
height: 40px;
border-radius: 20px;
background: #ff7675;
}
.inputModeSwitch {
    margin-top: 20px;
  }
  
  .switchContainer {
    display: flex;
    justify-content: space-around;
    gap: 10px;
  }
  
  .switchOption {
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  
  .switchOption:hover {
    background-color: #e0e0e0;
  }
  
  .switchOption.selected {
    background-color: #4caf50;
    color: white;
    border-color: #4caf50;
  }