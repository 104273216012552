.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
input#image2 {
  margin-top: 10px;
}
.loader {
  border: 8px solid #f3f3f3; /* Szürke szegély */
  border-top: 8px solid #3498db; /* Kék szegély */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s ease-in-out infinite;
  text-align: center;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 80px;
}

@keyframes spin {
  0% { transform: rotate(0deg); } /* 0° */
  100% { transform: rotate(360deg); } /* 360° */
}
.itemDiv {
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
  margin: 5px;
  width: 89%;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 15px;
}
canvas {
  margin: 10px;
}
.jobnametitle{
  font-size: 16px;
  font-weight: bold;
  border-radius:5px;
  border: 1px solid;
  padding:5px;
  display: inline-block;
  background-color: white;
}
p.inputLabel {
  font-size: 12px;
  color: black;
}
.App-header{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0 0 0 / 65%);
}
.profilepicinMasters {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  object-fit: cover;
  object-position: top;
  align-self: start;
  
  }
  .golden-shiny-div {
    border: 3px solid;
    border-image: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    border-image-slice: 1;
  }
.App-link {
  color: #61dafb;
}
.version{
  font-size: 11px;
  display: inline-flex;
  position: absolute;
  bottom: 5px;
  left: 45%;
  background-color: red;
  color: white;
  margin: auto;
  padding: 5px;
  border-radius: 15px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
p.financialDiv {
  min-width: 90px;
}
.row {
  display: flex;
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px;
  margin: 5px;
  width: fit-content;
  }
  .column {
    padding: 5px;
    }
  .leftcolumn {

    border-right: 1px solid black;
  }
  .date-picker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .summary-container {
    text-align: center;
    width: 80%;
  }
  
  .summary-table, .cost-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .summary-table th, .summary-table td, .cost-table th, .cost-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .summary-table th, .cost-table th {
    background-color: #f2f2f2;
  }
  
  .totals {
    margin-top: 20px;
    text-align: left;
    width: 100%;
  }
  
  .detailed-costs {
    margin-top: 20px;
    text-align: left;
  }
  
  .button-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .button-container .active {
    background: black;
    color: white;
  }
  
  .error {
    color: red;
  }
  .finance {
    font-family: Arial, sans-serif;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Popup háttér */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 1000;
}

/* Átlátszó háttér a popup mögött */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Gombok stílusa */
.popup button {
  margin-top: 10px;
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.popup button:hover {
  background-color: #0056b3;
}

.popup button:last-child {
  background-color: #6c757d;
}

.popup button:last-child:hover {
  background-color: #5a6268;
}

/* Input mezők stílusa */
.popup input[type="text"],
.popup input[type="number"],
.popup input[type="date"] {
  width: calc(100% - 20px);
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}