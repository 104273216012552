
.user {
    font-size: 16px;
    border: 1px solid gray;
    display: inline-block;
    padding: 3px;
    border-radius: 5px;
    margin-top: 5px;
    background-color: white;
    color: gray;
    padding:10px;
}
.buttonu {
margin-left: 5px;
}