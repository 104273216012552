.productRow {
    border: 1px solid white;
    border-radius: 25px;
    padding: 10px;
    margin: 8px;
    box-shadow: 0px 7px 10px 0px rgb(0 0 0 / 15%);
    display: inline-block;
    width: 300px;
}
body {
    background-color: #ffffff;
}
hr {
    padding: 0;
    margin: 10px;
}
.thdesign {
    font-size: 15px;
    background-color: black;
    color: white;
    }
.trdesign {
    font-size: 13px;
}
p {
    margin:0;
}
p.warranty {
    border: 2px solid black;
    display: inline-flex;
    font-size: 10px;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    font-weight: bolder;
    margin-top: 10px;
}
.productTable {
    margin-bottom: 80px;
}
.productType {
    font-size: 11px;
    color: white;
    background-color: black;
    display: inline-block;
    padding: 5px;
    border-radius: 25px;
    margin-top: 5px;
}
.perProd{
    margin: 2px;
}
h5 {
    margin: 0;
    font-size: 18px;
}
.timetable {
    font-size: 13px;
}
.halfdiv {
    width: 45%;
    display: inline-block;
    text-align:left;
}
.timediv {
    width: 55%;
    display: inline-block;
    text-align:right;
}
.centerDiv{
    padding-left: 10px;
    padding-right: 10px;
    align-items: top;
}
.locationName, .timeName{
    font-size: 11px;
    font-weight: 700;
    padding: 0px!important;
}
.timeName {
    font-weight: normal;
}
.location, .timetext{
    display: inline-flex;
    background: white;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    border-radius: 20px;
    align-items: center;
    color: rgb(0 0 0 / 65%);
    text-align: left;
    font-size: 11px;
    color:white;
}
.timetext {
    font-size: 10px;
}
.locationIcon, .clockIcon {
    height: 20px;
    width: 20px;
    background-color: red;
    border-radius: 100%;
    margin-right: 5px;
}
.financialDiv{
    border-radius: 25px;
    display:inline-block;
    text-align: center;
    color: black;
    padding: 3px;
    margin-top: 5px;
    font-size: 11px;
    font-weight: 500;
    border: 1.5px solid black;
}
h2{
margin-bottom: 10px;
}
.userName{
    font-size: 12px;
}
.gotoBlockLink{
    background-color: white;
    padding: 5px;
    border-radius: 25px;
    color: black;
    font-size: 15px;
    font-weight: 600;
    margin-top: 100px;
    text-decoration: none;
    border: 2px solid black;
}
input[type="text"] {
    margin: 5px;
    border: 1px solid #BDBDBD;
    border-radius: 25px;
    text-align: center;
    width:250px;
}
button.deleteButton {
    margin: 5px;
    background: red;
    border: none;
    border-radius: 5px;
    color: white;
}
.rowItem {
    display: flex;
    justify-content: center;
    font-size: 20px;
}
.minheight {
    min-height: 30px;
    }