.change-password-container {
    max-width: 420px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    font-family: 'Roboto', sans-serif;
    text-align: center;
  }
  
  .change-password-container h1 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .change-password-form .form-group {
    margin-bottom: 20px;
  }
  
  .change-password-form label {
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: left;
    color: #666666;
  }
  
  .change-password-form input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    margin-bottom: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
  }
  
  .change-password-form input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .password-strength {
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
  }
  
  .password-strength.too-weak {
    color: #ff4d4d;
  }
  
  .password-strength.weak {
    color: #ff7f50;
  }
  
  .password-strength.medium {
    color: #ffa500;
  }
  
  .password-strength.strong {
    color: #28a745;
  }
  
  .error-message {
    color: #ff4d4d;
    font-size: 14px;
    margin-top: 5px;
    text-align: left;
  }
  
  .submit-btn {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    background-color: #007bff;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .submit-btn:active {
    transform: translateY(0);
  }
  
  @media (max-width: 768px) {
    .change-password-container {
      margin: 30px 15px;
      padding: 15px;
    }
  
    .change-password-container h1 {
      font-size: 24px;
    }
  
    .change-password-form input {
      font-size: 14px;
      padding: 10px;
    }
  
    .submit-btn {
      font-size: 16px;
      padding: 12px;
    }
  }