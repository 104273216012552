.loginInput {
    display: block;
    padding-right: 15px !important;
    padding-left: 15px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    margin: auto !important;
    margin-bottom: 10px !important;
    border-radius: 20px !important;
    border: none;
    text-align: center;
    transition: 0.3s linear;
    width: 80% !important;

    height: 40px;
    font-size: 15px;
    border-radius: 5px!important;
}
.loginInput:focus {
    background: lightgray;
}
.submitbuttonLogin {
    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    color: white;
    transition: 0.3s linear;
    cursor: pointer;
height: 40px;
border-radius: 5px;
background: #ff7675;
width: 50%;
margin: 10px;
}
  
  /* Create a shine effect on the first row */
  .first-row {
    background: linear-gradient(135deg, rgba(255, 215, 0, 0.7) 0%, #00b894 50%, #00b894 100%) !important;
  }
.form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
.logo {
    height: 100px;
    margin-top: 70%;
    }
.submitbuttonLogin:hover {
    background-color: #ddd;
    color: #2B3A55;
}
.backgroundImage {
  background-image: url("../../img/backgroundImage.png");
  background-position: center;
}
/* Your regular CSS styles for all devices */

/* CSS styles only for desktop PCs (screen width larger than 768px) */
@media (min-width: 769px) {
    /* Add your desktop-specific styles here */
    .onlyonMobile {
      display: none;
      /* Add more styles as needed */
    }
  }
  @media (max-width: 769px) {
    /* Add your desktop-specific styles here */
    .onlyonPC {
      display: none!important;
      /* Add more styles as needed */
    }
  }
  